import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class CRMRetailService {
    readQuery(){
        var query = `query{
            GetCRMRetail{
                crm_retail_id
                store_name
                store_address
                store_phone_number
                sales_id
                sales_name
                status
                crm_retail_detail {
                crm_retail_id
                reimburse_cost
                reimburse_name
                status
                notes
                }
                notes
                created_at
                last_update
            }
        }`;
        return query;
    }
    async getSalesIdQuery(){
        var query = gql`query	{
            GetContact(ContactType:"Employee",ActiveFlag:"A"){
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var label = result.data.GetContact[i].contact_name
                var str = {value:result.data.GetContact[i].contact_id, label:label}
                arrayData.push(str);
            }
        }
        return arrayData;
    }   
    async deleteQuery(variables){
        const query = gql`
            mutation ($id:Int!) {
                DeleteCRMRetail (crm_retail_id:$id)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async addQuery(variables){
        const query = gql`
            mutation ($data:NewCRMRetail!) {
                CreateCRMRetail (NewCRMRetail:$data)
            }
        `
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        const query = gql`
            mutation ($id:Int!, $data:NewCRMRetail!) {
                UpdateCRMRetail (crm_retail_id:$id, NewCRMRetail:$data)
            }
        `
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}


export default new CRMRetailService();