import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class CRMProjectService {
    readQuery(){
        var query = `query{
            GetCRMProject{
                crm_project_id
                status
                project_id
                project_name
                sales_id
                sales_name
                company_name
                company_representative_name
                company_representative_position
                company_representative_email
                company_representative_phone_number
                crm_project_detail {
                    crm_project_id
                    reimburse_cost
                    reimburse_name
                    status
                    notes
                }
                notes
                created_at
                last_update
            }
        }`;
        return query;
    }
    
    async getProjectIdQuery(){
        var query = gql`query {
            GetTransactionProject (ProjectType:1) {
                project {
                    project_id
                    project_name
                }
            } 
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetTransactionProject.project != null){
            for (let i = 0; i < result.data.GetTransactionProject.project.length; i++) {
                var label = result.data.GetTransactionProject.project[i].project_name
                var str = { value:result.data.GetTransactionProject.project[i].project_id, label:label}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async getSalesIdQuery(){
        var query = gql`query	{
            GetContact(ContactType:"Employee",ActiveFlag:"A"){
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var label = result.data.GetContact[i].contact_name
                var str = {value:result.data.GetContact[i].contact_id, label:label}
                arrayData.push(str);
            }
        }
        return arrayData;
    }   

    async deleteQuery(variables){
        const query = gql`mutation($id:Int!){
            DeleteCRMProject(crm_project_id:$id)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async addQuery(variables){
        const query = gql`
            mutation ($data:NewCRMProject!) {
                CreateCRMProject (NewCRMProject:$data)
            }
        `
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        const query = gql`
            mutation ($id:Int!, $data:NewCRMProject!) {
                UpdateCRMProject (crm_project_id:$id, NewCRMProject:$data)
            }
        `
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new CRMProjectService();