<template>
    <div>
        <kendo-grid ref="grid"
                    :data-source="dataSource"
                    :pageable="true"
                    :filterable="true"
                    :sortable="true"
                    :columns=columns
                    v-on:detailinit="detailInit"
                    :resizable="true">
        </kendo-grid>
    </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import crmRetailService from '../Script/CRMRetailService.js';
import { kendo_grid } from '../../../../infrastructure/constant/variable.js'

export default {
    name: 'CRMRetailGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick'],
    data(){
        return{
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function() {
                            return { query: crmRetailService.readQuery() };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    }
                },
                schema: {
                    data: function(response) {
                        if (response.data.GetCRMRetail == null)
                            return [];
                        else
                            return response.data.GetCRMRetail;
                    },
                    total: function(response) {
                        if (response.data.GetCRMRetail == null)
                            return 0;
                        else
                            return response.data.GetCRMRetail.length;
                    }
                },
            }),
            columns:  [
                { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "store_name", title: "Nama Toko", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "store_address", title: "Alamat Toko", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "sales_name", title: "Sales", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "notes", title: "Keterangan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style:'text-align:center'},
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'dd-MM-yyyy') #"
                },
            ],
        }
    },
    mounted: function(){
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            editClick(dataItem, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.crm_retail_id);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("CRM Retail")
        },
        detailInit: function (e) {
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "CRMRetailDetailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(e.data.crm_retail_detail);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'reimburse_name', title: "Nama Tebusan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'reimburse_cost', title: "Jumlah Tebusan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{ style: "text-align:right;"}, editable: false, nullable: true, format:"{0:N0}" },
                    { field: 'notes', title: "Keterangan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },                   
                ],
            })
        },
    }
}
</script>

<style scoped>
</style>