<template>
    <div>
        <div class="modal fade" id="crmProjectModal" tabindex="-1" aria-labelledby="crmProjectModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="crmProjectModalLabel" class="font-weight-bold">{{ModalTitle}}{{ProjectId.label}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>

                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Project</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <v-select id="ProjectId" class="pb-3" :options="ProjectIdData" v-model="ProjectId" />
                                    <label id="errorProjectId" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Salesman</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <v-select id="SalesId" class="pb-3" :options="SalesIdData" v-model="SalesId" />
                                    <label id="errorSalesId" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Nama Perusahaan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <CInput id="CompanyName" v-model="CompanyName" class="font-weight-bold" />
                                    <label id="errorCompanyName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Nama Perusahaan Representatif</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <CInput id="CompanyRepresentativeName" v-model="CompanyRepresentativeName" class="font-weight-bold" />
                                    <label id="errorCompanyRepresentativeName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Posisi Perusahaan Representatif</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <CInput id="CompanyRepresentativePosition" v-model="CompanyRepresentativePosition" class="font-weight-bold" />
                                    <label id="errorCompanyRepresentativePosition" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Email Perusahaan Representatif</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <CInput id="CompanyRepresentativeEmail" v-model="CompanyRepresentativeEmail" class="font-weight-bold" />
                                    <label id="errorCompanyRepresentativeEmail" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">No Tlp. Perusahaan Representatif</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <CInput id="CompanyRepresentativePhoneNumber" v-model="CompanyRepresentativePhoneNumber" class="font-weight-bold"/>
                                    <label id="errorCompanyRepresentativePhoneNumber" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>

                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Keterangan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="4">
                                    <CInput id="Notes" v-model="Notes" class="font-weight-bold" />
                                    <label id="errorNotes" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            
                            <CRow class="p-3">
                                <div class="p-3 border rounded" style="width: 100%;">
                                    <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Detail CRM Project Masuk</h4></label>
                                    <hr>
                                    <datasource ref="detailDataSource" :data="this.DetailGridData" :page-size="10" :schema-model-fields="this.DetailSchemaModel"/>

                                    <kendo-grid ref="gridItem"
                                                :data-source-ref="'detailDataSource'"
                                                :pageable="true"
                                                :editable="true"
                                                :filterable="true"
                                                :sortable="true"
                                                :toolbar="['create']"
                                                :cellClose="cellClose"
                                                :resizable="true"
                                            >
           
                                        <kendo-grid-column  :field="'status'"
                                                            :title="'Status'"
                                                            :width="200"
                                                            :editor= "StatusDropDownEditor"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                        <kendo-grid-column  :field="'reimburse_name'"
                                                            :title="'Nama Tunjangan'"
                                                            :width="200"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                
                                        <kendo-grid-column  :field="'reimburse_cost'"
                                                            :title="'Jumlah Tunjangan'"
                                                            :width="200"
                                                            :format="'{0:N0}'"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                            :attributes="{style:'text-align:right'}"></kendo-grid-column>

                                        <kendo-grid-column  :field="'notes'"
                                                            :title="'Keterangan'"
                                                            :width="200"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                        
                                       
                                        <kendo-grid-column  :title="'&nbsp;'"
                                                            :attributes="{ class: 'k-text-center' }"
                                                            :command="['destroy']"
                                                            :width= 200></kendo-grid-column>
                                        
                                    </kendo-grid>
                                </div>
                            </CRow>
                            <br>
                            <label id="errorCRMProjectGeneral" class="form-error" style="display: none; color: red;"></label>
                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                                <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import crmProjectService from '../Script/CRMProjectService.js';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import { response } from '../../../../infrastructure/constant/response';


export default {
    name: 'CRMProjectForm',
    props: ['reload'],
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    data: function () {
        return {
            ModalTitle :'',
            CRMProjectId:'',
            Status:'',
            StatusData:[],
            ProjectId:'',
            ProjectIdData:[],
            SalesId:'',
            SalesIdData:[],
            CompanyName:'',
            CompanyRepresentativeName:'',
            CompanyRepresentativePosition:'',
            CompanyRepresentativeEmail:'',
            CompanyRepresentativePhoneNumber:'',
            //grid
            DetailGridData: [],
            DetailSchemaModel:{
                reimburse_name: { type: "string", editable: true, validation: {required: true}},
                reimburse_cost: { type: "number", editable: true, validation: {required: true, min:0}},
                notes: { type: "string", editable: true },
            },
            Notes:'',

            Error: 0,
            SaveType: '',
        }
    },
    // mounted(){

    // },
    methods: {
        cellClose(e){
            var grid = this.$refs.gridItem.kendoWidget();
            var dataItem = e.sender.dataItem($(e.container).parent());

            if (dataItem.status.value != undefined) {
                dataItem.status = dataItem.status.value;
            }
            // dataItem.status = dataItem.status.value;
        },       
        StatusDropDownEditor(container, options) {
            var vue = this;
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: false,
                    dataTextField: "label",
                    dataValueField: "value",
                    filter: "contains",
                    dataSource: {
                        data: vue.StatusData
                    }
                });
        },
        async addClick(){  
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Add CRM Project: '  
            this.CRMProjectId = ''
            this.StatusData = await globalfunc.globalDropdown('ddl_status_crm');
            this.Status = 'New';     
            this.ProjectId = '';
            this.ProjectIdData = await crmProjectService.getProjectIdQuery();
            this.SalesId = '';
            this.SalesIdData = await crmProjectService.getSalesIdQuery();
            this.CompanyName = '';
            this.CompanyRepresentativeName = '';
            this.CompanyRepresentativePosition = '';
            this.CompanyRepresentativePhoneNumber = '';
            this.CompanyRepresentativeEmail = '';
            this.Notes = ''
            
            this.DetailGridData = '';

            this.SaveType = 'Add';
            window.$('#crmProjectModal').modal('show');
        },
        async editClick(crmProjectData, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            
            this.ModalTitle = 'Edit CRM Project: '
            this.CRMProjectId = crmProjectData.crm_project_id;
            this.StatusData = await globalfunc.globalDropdown('ddl_status_crm');
            this.Status = crmProjectData.status;    
            this.ProjectIdData = await crmProjectService.getProjectIdQuery();   
            this.ProjectId = this.ProjectIdData.find(c => c.value == crmProjectData.project_id);
            this.SalesIdData = await crmProjectService.getSalesIdQuery();
            this.SalesId = this.SalesIdData.find(c => c.value == crmProjectData.sales_id)
            this.CompanyName = crmProjectData.company_name;
            this.CompanyRepresentativeName = crmProjectData.company_representative_name;
            this.CompanyRepresentativePosition = crmProjectData.company_representative_position;
            this.CompanyRepresentativeEmail = crmProjectData.company_representative_email;
            this.CompanyRepresentativePhoneNumber = crmProjectData.company_representative_phone_number;
            this.Notes = crmProjectData.notes;

            //grid
            this.DetailGridData = globalfunc.objectToArrayConverter(crmProjectData.crm_project_detail, 'CRM-DetailData');

            this.SaveType = 'Edit';
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View CRM Project: ';
            }

            window.$('#crmProjectModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");
            var gridData = this.$refs.gridItem.kendoWidget().dataSource._data;

            if(this.Status == ''){
                this.errorShow('errorStatus');
            }
            if(this.ProjectId == ''){
                this.errorShow('errorProjectId');
            }
            if(this.SalesId == ''){
                this.errorShow('errorSalesId');
            }
            if(this.CompanyName == ''){
                this.errorShow('errorCompanyName');
            }
            if(this.CompanyRepresentativeName == ''){
                this.errorShow('errorCompanyRepresentativeName');
            }
            if(this.CompanyRepresentativePosition == ''){
                this.errorShow('errorCompanyRepresentativePosition');
            }
            if(this.CompanyRepresentativeEmail == ''){
                this.errorShow('errorCompanyRepresentativeEmail');
            }
            if(this.CompanyRepresentativePhoneNumber == ''){
                this.errorShow('errorCompanyRepresentativePhoneNumber');
            }
            if(gridData.length == 0){
                this.$swal("Error", "Detail CRM Project harus diisi terlebih dahulu", "error");
                this.Error++;
            }
        },
        saveClick() {
            this.inputValidation();

            if(this.Error == 0){
                this.$loading(true);
                var gridData = this.$refs.gridItem.kendoWidget().dataSource._data;
                var detailGridData = [];
                
                for (let i = 0; i < gridData.length; i++) {
                    var str = 
                        { 
                            reimburse_name: gridData[i].reimburse_name,
                            reimburse_cost: gridData[i].reimburse_cost,
                            notes: gridData[i].notes,
                            status: gridData[i].status
                        } 
                    detailGridData.push(str);
                }

                //Add
                if(this.SaveType == 'Add'){
                    this.$loading(true);
                    
                    const crmProjectData = {
                        status: this.Status,
                        project_id: this.ProjectId.value,
                        sales_id: this.SalesId.value,
                        company_name: this.CompanyName,
                        company_representative_name: this.CompanyRepresentativeName,
                        company_representative_position: this.CompanyRepresentativePosition,
                        company_representative_email: this.CompanyRepresentativeEmail,
                        company_representative_phone_number: this.CompanyRepresentativePhoneNumber,
                        crm_project_detail: detailGridData,
                        notes: this.Notes
                    };
                    
                    const variables = {
                        data : crmProjectData
                    }

                    crmProjectService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#crmProjectModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                //Edit
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const crmProjectData = {
                        status: this.Status,
                        project_id: this.ProjectId.value,
                        sales_id: this.SalesId.value,
                        company_name: this.CompanyName,
                        company_representative_name: this.CompanyRepresentativeName,
                        company_representative_position: this.CompanyRepresentativePosition,
                        company_representative_email: this.CompanyRepresentativeEmail,
                        company_representative_phone_number: this.CompanyRepresentativePhoneNumber,
                        crm_project_detail: detailGridData,
                        notes: this.Notes
                    };
                    
                    const variables = {
                        id : this.CRMProjectId,
                        data : crmProjectData
                    }

                    crmProjectService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#crmProjectModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>
<style scoped>
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
</style>